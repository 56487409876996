import React, { useEffect, useMemo, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';

import { Victime } from '../../../types/victime.type';
import { useAppDispatch, useAppSelector } from '../../../hooks/store';
import { fetchAllDommages } from '../../../slices/dommage';
import { Dommage } from '../../../types/dommage.type';
import { VictimeIndirecte } from '../../../types/victimeIndirecte.type';
import { EditDommageDialog } from './EditDommageDialog';
import {
  appConstantsSelector,
  baremeSelectors,
  dommageSelectors,
  monetaryErosionSelectors,
  prejudiceSelectors,
  victimeIndirecteSelectors,
} from '../../../store/selectors';
import { fDate } from '../../../helpers/formatTime';
import { TOpenEditProcedure } from './index';
import { Edit } from '@mui/icons-material';
import { EditVictimeIndirecteDialog } from './EditVictimeIndirecteDialog';
import { fetchAllVictimesIndirectes } from '../../../slices/victimeIndirecte';
import { ProcedureType } from 'src/types/procedure.type';
import {
  getPrejudicesTotal,
  PrejudicesTotalAmount,
} from 'src/helpers/prejudices/total';
import {
  fetchAllPrejudices,
  updateMultiplePrejudices,
} from '../../../slices/prejudice';
import { fCurrency } from '../../../helpers/formatNumber';
import { updateProcedure } from '../../../slices/procedure';
import { ConfirmationDialog } from '../../../components/basic/ConfirmationDialog';
import { EssentialInformations } from 'src/components/client/EssentialInformations';
import { theme } from 'src/constants/theme';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'lodash';
import { getAutomaticallyUpdatedPrejudicesDto } from 'src/helpers/prejudices/automaticUpdate';
import i18next from 'i18next';
import { useCanUpdateVictime } from 'src/hooks/store/victime.hook';
import { useDisplayedOrganization } from 'src/hooks/store/organization.hooks';
import { hideSensitiveFields } from 'src/helpers/organization';

interface Props {
  victime: Victime;
  setOpenEditProcedure: React.Dispatch<
    React.SetStateAction<TOpenEditProcedure>
  >;
}

export const Procedure: React.FC<Props> = ({
  victime,
  setOpenEditProcedure,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { procedureId } = useParams<any>();

  const dommages = useAppSelector(dommageSelectors.selectAll);
  const victimesIndirectes = useAppSelector(
    victimeIndirecteSelectors.selectAll,
  );
  const appConstants = useAppSelector(appConstantsSelector.selectAll);
  const baremes = useAppSelector(baremeSelectors.selectAll);
  const { organization } = useDisplayedOrganization();
  const [dommageConfirmDialog, setDommageConfirmDialog] = useState(false);

  const [createDommageOpened, setCreateDommageOpened] = useState<
    { dommage?: Dommage } | undefined
  >(undefined);
  const [editVictimeIndirecteOpened, setEditVictimeIndirecteOpened] = useState<
    { victimeIndirecte?: VictimeIndirecte } | undefined
  >(undefined);
  const [automaticUpdateDone, setAutomaticUpdateDone] = useState(false);
  const [automaticUpdateTried, setAutomaticUpdateTried] = useState(false);
  useEffect(() => {
    if (procedureId) {
      setAutomaticUpdateDone(false);
      setAutomaticUpdateTried(false);
    }
  }, [procedureId]);
  const procedure = useMemo(
    () => victime.procedures.find((procedure) => procedure._id === procedureId),
    [procedureId, victime, victime.procedures],
  );
  const { prejudices, monetaryErosion } = useAppSelector((state) => ({
    prejudices: prejudiceSelectors.selectAll(state),
    monetaryErosion: monetaryErosionSelectors.selectAll(state),
  }));

  useEffect(() => {
    if (procedure?._id && procedureId) {
      dispatch(fetchAllDommages(procedure._id));
      dispatch(fetchAllVictimesIndirectes(procedureId));
    }
  }, [procedure?._id]);

  if (!procedure) {
    return <></>;
  }

  useEffect(() => {
    if (procedure?._id) {
      dispatch(fetchAllPrejudices(procedure._id));
    }
  }, []);

  useEffect(() => {
    if (
      victime &&
      prejudices.length > 0 &&
      procedure &&
      baremes &&
      !automaticUpdateDone
    ) {
      setAutomaticUpdateTried(true);
      if (!appConstants || appConstants.length === 0 || !appConstants[0]) {
        return;
      }
      const dto = getAutomaticallyUpdatedPrejudicesDto({
        prejudices,
        procedure,
        victimesIndirectes,
        appConstants: appConstants?.[0],
        baremes,
        victime,
      });
      if (dto.prejudices.length > 0) {
        dispatch(
          updateMultiplePrejudices({
            procedureId: procedure._id,
            dto,
          }),
        );
        setAutomaticUpdateDone(true);
      }
    }
  }, [prejudices, procedure, appConstants, baremes]);

  const montantIndemnisation: PrejudicesTotalAmount = useMemo(
    () =>
      procedure &&
      automaticUpdateTried &&
      prejudices.every((prejudice) => prejudice.parent === procedure._id)
        ? getPrejudicesTotal({
            victime,
            victimesIndirectes,
            procedure,
            prejudices,
            dateLiquidation: procedure.dateLiquidation
              ? new Date(procedure.dateLiquidation)
              : undefined,
            monetaryErosions: monetaryErosion,
            dateConsolidation: procedure.dateConsolidation
              ? new Date(procedure.dateConsolidation)
              : undefined,
            dateDeces: victime.dateDeces
              ? new Date(victime.dateDeces)
              : undefined,
          })
        : {
            victime: 0,
            tiersPayeurs: 0,
            proche: null,
            rentes: null,
            total: 0,
          },
    [
      prejudices,
      victime,
      dommages,
      procedure,
      monetaryErosion,
      automaticUpdateTried,
    ],
  );

  const startCalculations = async () => {
    if (
      procedure?._id &&
      victime?._id &&
      !procedure?.isInitiated &&
      procedureId
    ) {
      await dispatch(
        updateProcedure({
          victimeId: victime._id,
          procedureId,
          data: {
            isInitiated: true,
          },
        }),
      );
    }
    navigate(`./prejudices`);
  };

  const handleCalcuationButtonClick = () => {
    if (procedure?.isInitiated) {
      navigate(`./prejudices`);
    } else {
      startCalculations();
    }
  };

  const canUpdateVictime = useCanUpdateVictime(victime);

  const shouldHideSensitiveFields =
    organization && hideSensitiveFields(organization);
  return (
    <>
      <EssentialInformations victime={victime} procedure={procedure} />
      <Box
        sx={{
          overflowY: 'auto',
          margin: theme.spacing(0, 2),
          flexGrow: 1,
          height: '0px',
        }}
      >
        <Stack direction="row">
          <Box flex={1}>
            <Typography sx={{ marginTop: 2 }} variant="h6">
              {procedure.intitule}
            </Typography>
          </Box>
          <Button
            sx={(theme) => ({
              color: theme.palette.primary.main,
              backgroundColor: theme.palette.common.white,
              transform: 'scale(1)',
              transition: '0.12s linear',

              '&:hover': {
                color: theme.palette.common.white,
                backgroundColor: theme.palette.primary.main,
                transform: 'scale(1.1)',
                transition: '0.12s linear',
              },
              height: 'fit-content',
              margin: theme.spacing(2),
            })}
            aria-label="edit"
            onClick={() => {
              setOpenEditProcedure({ procedure });
            }}
            disabled={!canUpdateVictime}
          >
            {t('pages.Procedure.editProcedure')}
          </Button>
        </Stack>
        <Stack
          direction="row"
          spacing={2}
          sx={{ fontWeight: 'bold', fontSize: '0.875em', mb: 4 }}
        >
          {procedure.dateExpertise && (
            <span>
              {t('procedure.fields.dateExpertise.label')}:{' '}
              {fDate(procedure.dateExpertise)}
            </span>
          )}
          <span>
            {capitalize(
              t(
                `procedure.fields.dateLiquidation.label.byProcedureType.${procedure.procedureType}`,
              ) || '',
            )}
            :{' '}
            {procedure.dateLiquidation ? (
              fDate(procedure.dateLiquidation)
            ) : (
              <Typography
                variant="body2"
                sx={{
                  color: 'primary.main',
                  cursor: 'pointer',
                  userSelect: 'none',
                }}
                onClick={() => setOpenEditProcedure({ procedure })}
                component="span"
              >
                {t('pages.Procedure.fillDateLiquidation')}
              </Typography>
            )}
          </span>
        </Stack>
        <Grid container spacing={4}>
          <Grid item xs={5}>
            {shouldHideSensitiveFields ? null : (
              <>
                <Typography variant="subtitle2">
                  {t('procedure.fields.resumeAffaire.label')}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  {procedure.resumeAffaire.trim().length > 0
                    ? procedure.resumeAffaire
                    : 'Non renseigné'}
                </Typography>
              </>
            )}
          </Grid>
          <Grid item xs={4}>
            <Stack direction="row" spacing={1}>
              <Typography variant="subtitle2">
                {t('pages.Procedure.montantIndemnisation')} :
              </Typography>
              <Typography variant="subtitle2" color="green">
                {fCurrency(montantIndemnisation.total)}
              </Typography>
            </Stack>
            {procedure.dateLiquidation ||
            procedure.procedureType === ProcedureType.provision ? (
              <Button
                sx={(theme) => ({
                  mt: 1,
                  color: theme.palette.common.white,
                  backgroundColor: theme.palette.primary.main,
                  transform: 'scale(1)',
                  transition: '0.12s linear',
                  '&:hover': {
                    backgroundColor: theme.palette.secondary.main,
                    transform: 'scale(1.1)',
                    transition: '0.12s linear',
                  },
                  height: 'fit-content',
                  padding: '15px 25px 15px 25px',
                })}
                onClick={handleCalcuationButtonClick}
              >
                {t('pages.Procedure.accederCalculs')}
              </Button>
            ) : (
              <Typography variant="caption" color="error.main">
                {t('pages.Procedure.mustFillDateLiquidation')}
              </Typography>
            )}
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2">
              {t('pages.Procedure.victimesIndirectes.title')}
            </Typography>
            <Stack direction="column" spacing={1}>
              <Stack direction="column" spacing={0.5}>
                {victimesIndirectes.map((victimeIndirecte) => (
                  <div
                    key={victimeIndirecte._id}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      variant="caption"
                      sx={{ color: 'text.primary' }}
                    >
                      {`${victimeIndirecte.prenom} ${victimeIndirecte.nom} (${i18next.t(`victimeIndirecte.form.fields.lienVictime.options.${victimeIndirecte.lienVictime}`)})`}
                    </Typography>
                    {canUpdateVictime && (
                      <IconButton
                        size="small"
                        color="default"
                        onClick={() =>
                          setEditVictimeIndirecteOpened({ victimeIndirecte })
                        }
                      >
                        <Edit />
                      </IconButton>
                    )}
                  </div>
                ))}
              </Stack>
              <Button
                variant="text"
                sx={{
                  fontWeight: 'bold',
                  fontSize: '14px',
                  textTransform: 'none',
                  textAlign: 'left',
                  paddingX: 0,
                }}
                onClick={() =>
                  canUpdateVictime
                    ? setEditVictimeIndirecteOpened({})
                    : navigate('/subscription')
                }
                disabled={!canUpdateVictime && !!organization}
              >
                {canUpdateVictime
                  ? t(
                      'pages.Procedure.victimesIndirectes.add.validSubscription',
                    )
                  : !organization
                    ? t(
                        'pages.Procedure.victimesIndirectes.add.invalidSubscription',
                      )
                    : t('pages.Procedure.victimesIndirectes.add.noPermission')}
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            {shouldHideSensitiveFields ? null : (
              <>
                <Typography variant="subtitle2">
                  {t('pages.Procedure.commentaireExpertise')}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  {procedure.commentaireExpertise.trim().length > 0
                    ? procedure.commentaireExpertise
                    : t('pages.Procedure.noCommentaireExpertise')}
                </Typography>
              </>
            )}
          </Grid>
        </Grid>
        <EditDommageDialog
          procedureId={procedure._id}
          victime={victime}
          open={!!createDommageOpened}
          dommage={createDommageOpened?.dommage}
          onClose={() => setCreateDommageOpened(undefined)}
          onClickClose={() => setDommageConfirmDialog(true)}
        />
        <EditVictimeIndirecteDialog
          procedure={procedure}
          victimeIndirecte={editVictimeIndirecteOpened?.victimeIndirecte}
          open={!!editVictimeIndirecteOpened}
          onClose={() => setEditVictimeIndirecteOpened(undefined)}
        />
        <ConfirmationDialog
          open={dommageConfirmDialog}
          onCancel={() => setDommageConfirmDialog(false)}
          onConfirm={() => {
            setCreateDommageOpened(undefined);
            setDommageConfirmDialog(false);
          }}
          title={t('pages.Procedure.DFTTDialog.title')}
          description={t('pages.Procedure.DFTTDialog.description') || ''}
          confirmText={t('pages.Procedure.DFTTDialog.confirm') || ''}
          cancelText={t('pages.Procedure.DFTTDialog.cancel') || ''}
        />
      </Box>
    </>
  );
};
