import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Dialog,
  DialogContent,
  ModalProps,
  Stack,
  Typography,
} from '@mui/material';
import { omit } from 'lodash';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { useTranslation } from 'react-i18next';
import { ComputedPropsForm } from 'src/components/forms/ComputedPropsForm';
import { SelectFieldForm } from 'src/components/forms/SelectFieldForm';
import { Procedure } from 'src/types/procedure.type';
import { ConfirmationDialog } from '../../../components/basic/ConfirmationDialog';
import { DatePickerForm } from '../../../components/forms/DatePickerForm';
import { TextFieldForm } from '../../../components/forms/TextFieldForm';
import { useAppDispatch } from '../../../hooks/store';
import { deletePrejudiceByTypes } from '../../../slices/prejudice';
import {
  createVictimeIndirecte,
  deleteVictimeIndirecte,
  updateVictimeIndirecte,
} from '../../../slices/victimeIndirecte';
import {
  CreateVictimeIndirecteDto,
  LIEN_VICTIME_LIST,
  LIEU_DE_VIE_LIST,
  SEXE_LIST,
  VictimeIndirecte,
} from '../../../types/victimeIndirecte.type';

interface Props {
  procedure: Procedure;
  victimeIndirecte?: VictimeIndirecte;
  open: boolean;
  onClose: ModalProps['onClose'];
}

export const EditVictimeIndirecteDialog: React.FC<Props> = ({
  procedure,
  victimeIndirecte,
  open,
  onClose,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [openConfirmationDelete, setOpenConfirmationDelete] = useState(false);

  const procedureId = procedure._id;

  const initialValues: CreateVictimeIndirecteDto = {
    nom: victimeIndirecte?.nom || '',
    prenom: victimeIndirecte?.prenom || '',
    sexe: victimeIndirecte?.sexe || 'm',
    dateNaissance: victimeIndirecte?.dateNaissance || (null as any),
    lienVictime: victimeIndirecte?.lienVictime || 'conjointConcubin',
    lieuDeVie: victimeIndirecte?.lieuDeVie,
  };
  const { control, handleSubmit, reset, ...useFormReturn } =
    useForm<CreateVictimeIndirecteDto>({
      defaultValues: initialValues,
      mode: 'onTouched',
      resolver: yupResolver(
        yup.object({
          nom: yup.string().optional(),
          prenom: yup.string().required(),
          sexe: yup.string().oneOf(SEXE_LIST).required(),
          dateNaissance: yup
            .string()
            .isDateString()
            .typeError('À renseigner')
            .required()
            .maxDate(
              undefined,
              `Date de naissance postérieure à la date de liquidation`,
              procedure.dateLiquidation
                ? new Date(procedure.dateLiquidation)
                : undefined,
            ),
          lienVictime: yup
            .string()
            .oneOf([...LIEN_VICTIME_LIST])
            .required(),
          lieuDeVie: yup
            .string()
            .optional()
            .oneOf([...LIEU_DE_VIE_LIST]),
        }),
      ),
    });

  useEffect(() => {
    if (open) {
      reset(initialValues);
    }
  }, [open]);

  const onSubmit = (values: CreateVictimeIndirecteDto) => {
    if (victimeIndirecte) {
      dispatch(
        updateVictimeIndirecte({
          procedureId,
          victimeIndirecteId: victimeIndirecte._id,
          data: omit(values, ['statutVictime', 'lienVictime']),
        }),
      );
    } else {
      dispatch(createVictimeIndirecte({ procedureId, data: values }));
    }

    if (onClose) {
      onClose({}, 'backdropClick');
    }
  };

  const onDelete = async () => {
    setOpenConfirmationDelete(false);
    if (victimeIndirecte) {
      await dispatch(
        deleteVictimeIndirecte({
          procedureId,
          victimeIndirecteId: victimeIndirecte._id,
        }),
      );
      await dispatch(
        deletePrejudiceByTypes({
          procedureId,
          types: [
            'FRAIS_OBSEQUES',
            'PREJUDICE_ACCOMPAGNEMENT_FIN_DE_VIE',
            'PERTES_REVENUS_DES_PROCHES',
            'FRAIS_DIVERS_DES_PROCHES',
            'PREJUDICE_AFFECTION_OU_MORAL',
            'PREJUDICES_EXTRA_PATRIMONIAUX_EXCEPTIONNELS',
          ],
        }),
      );
    }

    if (onClose) {
      onClose({}, 'backdropClick');
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <FormProvider {...{ control, handleSubmit, reset, ...useFormReturn }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent sx={{ mx: 4, minWidth: '400px' }}>
            <Stack spacing={2}>
              <Typography variant="h4" component="h2" align="center">
                {t('victimeIndirecte.form.title')}
              </Typography>

              <TextFieldForm
                control={control}
                name="prenom"
                label={t('victimeIndirecte.form.fields.firstName.label')}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
              <TextFieldForm
                control={control}
                name="nom"
                label={t('victimeIndirecte.form.fields.lastName.label')}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
              <SelectFieldForm
                name="sexe"
                control={control}
                variant="outlined"
                label={t('victimeIndirecte.form.fields.sexe.label')}
                fullWidth
                InputLabelProps={{ shrink: true }}
                disabled={!!victimeIndirecte}
                options={SEXE_LIST.map((sexe) => ({
                  value: sexe,
                  label: t(`victimeIndirecte.form.fields.sexe.options.${sexe}`),
                }))}
              />
              <DatePickerForm
                name="dateNaissance"
                control={control}
                label={t('victimeIndirecte.form.fields.birthDate.label')}
                TextFieldProps={{ InputLabelProps: { shrink: true } }}
                disabled={!!victimeIndirecte}
                required
                maxDate={
                  procedure.dateLiquidation
                    ? new Date(procedure.dateLiquidation)
                    : undefined
                }
              />
              <SelectFieldForm
                control={control}
                name="lienVictime"
                label={t('victimeIndirecte.form.fields.lienVictime.label')}
                InputLabelProps={{ shrink: true }}
                disabled={!!victimeIndirecte}
                options={LIEN_VICTIME_LIST.map((value) => ({
                  value,
                  label: t(
                    `victimeIndirecte.form.fields.lienVictime.options.${value}`,
                  ),
                }))}
              />
              <ComputedPropsForm
                control={control}
                watchFields={['lienVictime']}
                compute={([lienVictime]) => ({
                  hidden: !(
                    lienVictime === 'enfant' || lienVictime === 'frereSoeur'
                  ),
                })}
                render={() => (
                  <SelectFieldForm
                    control={control}
                    name="lieuDeVie"
                    label={t('victimeIndirecte.form.fields.lieuDeVie.label')}
                    InputLabelProps={{ shrink: true }}
                    disabled={!!victimeIndirecte}
                    options={LIEU_DE_VIE_LIST.map((value) => ({
                      value,
                      label: t(
                        `victimeIndirecte.form.fields.lieuDeVie.options.${value}`,
                      ),
                    }))}
                  />
                )}
              />
              <Stack spacing={2} direction="row">
                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  sx={{ flex: 2 }}
                >
                  {t('common.save')}
                </Button>
                {!!victimeIndirecte && (
                  <Button
                    fullWidth
                    onClick={() => setOpenConfirmationDelete(true)}
                    variant="outlined"
                    sx={{ flex: 1 }}
                  >
                    {t('common.delete')}
                  </Button>
                )}
              </Stack>
            </Stack>
          </DialogContent>
        </form>
      </FormProvider>
      <ConfirmationDialog
        title={t('victimeIndirecte.form.confirmDeleteDialog.title')}
        description={
          t('victimeIndirecte.form.confirmDeleteDialog.description') || ''
        }
        open={openConfirmationDelete}
        confirmText={
          t('victimeIndirecte.form.confirmDeleteDialog.confirm') || ''
        }
        cancelText={t('victimeIndirecte.form.confirmDeleteDialog.cancel') || ''}
        onCancel={() => setOpenConfirmationDelete(false)}
        onConfirm={onDelete}
      />
    </Dialog>
  );
};
