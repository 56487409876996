import React from 'react';
import {
  FormControlLabel,
  FormControlLabelProps,
  Radio,
  RadioGroup,
  RadioGroupProps,
  Tooltip,
} from '@mui/material';
import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';

interface Props<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
> extends UseControllerProps<TFieldValues, TName> {
  options?: (Omit<FormControlLabelProps, 'control'> & {
    tooltipText?: string;
    isDisabled?: (value: string) => boolean;
  })[];
}

export const RadioFormField = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  name,
  control,
  rules,
  shouldUnregister,
  defaultValue,
  options,
  ...props
}: Props<TFieldValues, TName> & RadioGroupProps) => {
  const {
    field: { onChange, value, ...otherField },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
    shouldUnregister,
    defaultValue,
  });
  return (
    <Tooltip title={error?.message || ''}>
      <RadioGroup
        {...props}
        {...otherField}
        value={value}
        onChange={(_, value) => onChange(value)}
      >
        {options?.map(({ label, ...checkboxProps }, index) => (
          <Tooltip
            title={checkboxProps.tooltipText || ''}
            placement="top"
            key={index}
          >
            <FormControlLabel
              {...checkboxProps}
              disabled={
                checkboxProps.disabled ||
                (checkboxProps.isDisabled
                  ? checkboxProps.isDisabled(checkboxProps.value as string)
                  : false)
              }
              control={<Radio />}
              label={label}
              sx={{
                color: error ? 'error.main' : 'inherit',
              }}
            />
          </Tooltip>
        ))}
      </RadioGroup>
    </Tooltip>
  );
};
