import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, InputAdornment, Stack } from '@mui/material';
import i18next from 'i18next';
import _ from 'lodash';
import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DintilhacText } from 'src/components/client/prejudiceFormComponents/DintilhacText';
import { CalculsFormCalendrierAssistance } from 'src/constants/calculs';
import { prejudiceBaseDefaultValues } from 'src/constants/prejudice/defaultValues';
import {
  getDefaultMinAndMaxDate,
  validationSchemaNumeroPieces,
  validationSchemaPrejudiceBase,
} from 'src/constants/prejudice/validation';
import { getShouldNotDisplayCapitalisation } from 'src/helpers/prejudices/capitalisation';
import { dateString } from 'src/helpers/yup';
import { Procedure } from 'src/types/procedure.type';
import { Victime } from 'src/types/victime.type';
import * as yup from 'yup';
import {
  assistanceRowTypes,
  assistanceUniteNombresHeure,
  PrejudiceFormCalendrierAssistance,
  PrejudiceFormCalendrierAssistanceRow,
  PrejudiceType,
} from '../../../types/prejudice.type';
import { ComputedPropsForm } from '../../forms/ComputedPropsForm';
import { TextFieldForm } from '../../forms/TextFieldForm';
import { PrejudiceContainer } from '../PrejudiceContainer';
import { Chiffrage } from '../prejudiceFormComponents/Chiffrage';
import { DintilhacButton } from '../prejudiceFormComponents/DintilhacButton';
import {
  defaultTableAssistanceRowEchu,
  TableAssistance,
} from '../prejudiceFormComponents/TableAssistance/TableAssistance';
import { TotalReparti } from '../prejudiceFormComponents/TotalReparti';
import { PrejudiceFormProps } from '../PrejudiceFormProps';
import { SavePrejudiceButton } from '../SavePrejudiceButton';

interface Props extends PrejudiceFormProps<PrejudiceFormCalendrierAssistance> {
  DFTPRows: PrejudiceFormCalendrierAssistanceRow[] | null;
}

export const validationSchemaCalendrierAssistanceRow = ({
  victime,
  procedure,
  prejudiceType,
}: {
  victime: Victime;
  procedure: Procedure;
  prejudiceType: PrejudiceType;
}): yup.ObjectSchema<PrejudiceFormCalendrierAssistanceRow> => {
  const { minDate, maxDate } = getDefaultMinAndMaxDate({
    victime,
    procedure,
    prejudiceType,
  });
  return yup.object({
    numerosPieces: validationSchemaNumeroPieces,
    joursPeriode: yup.number().defined().min(0),
    joursConges: yup.number().defined().min(0),
    joursRetenus: yup.number().defined().min(0),
    quantite: yup.number().defined().min(0),
    deductionFiscale: yup.number().defined().min(0),
    dateDebut: dateString()
      .nullable()
      .required()
      .minDate(undefined, minDate.message, minDate.date)
      .maxDate(undefined, maxDate.message, maxDate.date, { strict: true }),
    dateFin: dateString()
      .nullable()
      .required()
      .minDate(
        'dateDebut',
        i18next.t('validation.prejudices.dates.minDateDebut') || '',
      )
      .maxDate(undefined, maxDate.message, maxDate.date, { strict: true }),
    joursHospitalisationADeduire: yup.number().required().min(0),
    deduireJoursHospitalisation: yup.boolean().required(),
    totalJours: yup.number().required().min(0),
    typeAssistance: yup.string().optional(),
    forfaitHoraire: yup.number().required().min(0),
    nombreHeureParUniteChoisie: yup.number().required().min(0),
    uniteNombreHeure: yup
      .string()
      .required()
      .oneOf(assistanceUniteNombresHeure),
    dateJustificatif: dateString()
      .nullable()
      .defined()
      .when('montantsDejaRevalorises', {
        is: false,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema,
      }),
    montantsDejaRevalorises: yup.boolean().optional().defined(),
    montantTotal: yup.number().required().min(0),
    priseEnChargeTiersPayeurs: yup
      .array()
      .defined()
      .of(
        yup.object({
          tiersPayeur: yup.string().oneOf(procedure.tiersPayeurs).required(),
          montant: yup.number().required(),
        }),
      ),
    autresDeductions: yup.number().required().min(0),
    resteACharge: yup.number().defined().min(0),

    annualiseMontantTotal: yup.number().optional().min(0),
    commentaires: yup.string(),
    notes: yup.string().optional().defined(),
    isDFTPRow: yup.boolean().optional(),
    type: yup.string().oneOf(assistanceRowTypes).required(),
  });
};

export const validationSchemaCalendrierAssistance = ({
  victime,
  procedure,
  prejudiceType,
}: {
  victime: Victime;
  procedure: Procedure;
  prejudiceType: PrejudiceType;
}): yup.ObjectSchema<PrejudiceFormCalendrierAssistance> =>
  validationSchemaPrejudiceBase.shape({
    rows: yup.array().defined().of(
      validationSchemaCalendrierAssistanceRow({
        procedure,
        victime,
        prejudiceType,
      }),
    ),
    forfaitHoraire: yup.number().nullable().defined(),
    isDFTPRowsAdded: yup.boolean().optional(),
    revalorisationCoefficientsType: yup
      .string()
      .oneOf(['annuel', 'smic'])
      .required(),
  });

export const calendrierAssistanceDefaultValues = ({
  procedure,
  values,
  dateLiquidation,
  prejudiceType,
}: {
  procedure: Procedure;
  values: PrejudiceFormCalendrierAssistance | undefined;
  dateLiquidation: Date | undefined;
  prejudiceType: PrejudiceType;
}): PrejudiceFormCalendrierAssistance => ({
  ...prejudiceBaseDefaultValues({
    prejudiceType,
    values,
  }),
  notes: values?.notes || '',
  forfaitHoraire: values?.forfaitHoraire || 0,
  rows: _.sortBy(
    values?.rows.map((row) => ({
      ...defaultTableAssistanceRowEchu({
        tiersPayeurs: procedure.tiersPayeurs,
        dateLiquidation,
        isCalculCapitalisation: false,
      }),
      ...row,
      priseEnChargeTiersPayeurs: procedure.tiersPayeurs.map((tiersPayeur) => ({
        tiersPayeur,
        montant:
          (Array.isArray(row.priseEnChargeTiersPayeurs)
            ? row.priseEnChargeTiersPayeurs
            : []
          ).find(
            (priseEnChargeTiersPayeur) =>
              priseEnChargeTiersPayeur.tiersPayeur === tiersPayeur,
          )?.montant || 0,
      })),
    })) || [],
    ['dateDebut', 'typeAssistance'],
  ),
  isDFTPRowsAdded: values?.isDFTPRowsAdded || false,
  revalorisationCoefficientsType:
    values?.revalorisationCoefficientsType || 'smic',
});

export const FormCalendrierAssistance: React.FC<Props> = ({
  victime,
  procedure,
  values,
  monetaryErosions,
  dateLiquidation,
  DFTPRows,
  allNumerosPieces,
  dateConsolidation,
  dateDeces,
  ...props
}) => {
  const defaultValues: PrejudiceFormCalendrierAssistance = useMemo(
    () =>
      calendrierAssistanceDefaultValues({
        procedure,
        values,
        dateLiquidation,
        prejudiceType: props.prejudiceType,
      }),
    [values, procedure.tiersPayeurs, dateLiquidation],
  );
  const useFormReturn = useForm<PrejudiceFormCalendrierAssistance>({
    defaultValues,
    resolver: yupResolver(
      validationSchemaCalendrierAssistance({
        procedure,
        victime,
        prejudiceType: props.prejudiceType,
      }),
    ),
  });
  const { control, setValue } = useMemo(() => useFormReturn, [useFormReturn]);

  const { t } = useTranslation();
  const shouldNotDisplayCapitalisation = getShouldNotDisplayCapitalisation({
    dateConsolidation,
    dateLiquidation,
    dateDeces,
  });

  const [showDintilhac, setShowDintilhac] = React.useState<boolean>(false);

  return (
    <PrejudiceContainer<PrejudiceFormCalendrierAssistance>
      {...props}
      procedure={procedure}
      victime={victime}
      monetaryErosions={monetaryErosions}
      {...useFormReturn}
      renderPrejudice={() => {
        return (
          <Stack direction="column" spacing={4} flex={1}>
            <TextFieldForm
              control={control}
              name="notes"
              label={t('prejudice.fields.notes.label')}
              placeholder={t('prejudice.fields.notes.placeholder') || ''}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <DintilhacButton onClick={() => setShowDintilhac(true)} />
                  </InputAdornment>
                ),
              }}
              multiline
              maxRows={4}
              fullWidth
            />
            <DintilhacText
              open={showDintilhac}
              setOpen={setShowDintilhac}
              label={t('prejudice.fields.dintilhac.label')}
              content={i18next.t(
                `prejudice.prejudicesTypes.${props.prejudiceType}.introduction`,
                {
                  defaultValue: '',
                },
              )}
            />
            <TableAssistance
              control={control}
              tiersPayeurs={procedure.tiersPayeurs}
              hospitalisations={procedure.hospitalisations}
              dateLiquidation={dateLiquidation}
              dateConsolidation={dateConsolidation}
              monetaryErosions={monetaryErosions}
              prejudiceType={props.prejudiceType}
              procedure={procedure}
              victime={victime}
              DFTPRows={DFTPRows}
              setValue={setValue}
              allNumerosPieces={allNumerosPieces}
              shouldNotDisplayCapitalisation={shouldNotDisplayCapitalisation}
              dateDeces={dateDeces}
            />
            <Stack direction="row-reverse">
              <ComputedPropsForm
                control={control}
                watchFields={['rows', 'revalorisationCoefficientsType']}
                compute={([rows, revalorisationCoefficientsType]) => {
                  const totaux = CalculsFormCalendrierAssistance.totaux({
                    rows,
                    partResponsabilite: procedure.partResponsabilite,
                    tiersPayeurs: procedure.tiersPayeurs,
                    dateLiquidation,
                    monetaryErosions,
                    withDeductionFiscale: true,
                    revalorisationCoefficientsType,
                  });
                  return {
                    props: totaux,
                  };
                }}
                render={(props) => {
                  const { resteACharge, priseEnChargeTiersPayeurs } =
                    props as ReturnType<
                      typeof CalculsFormCalendrierAssistance.totaux
                    >;
                  return (
                    <TotalReparti
                      totalResteACharge={resteACharge.total.value}
                      priseEnChargeTiersPayeurs={Object.entries(
                        priseEnChargeTiersPayeurs,
                      ).reduce(
                        (
                          accumulator,
                          [
                            tiersPayeur,
                            {
                              total: { value },
                            },
                          ],
                        ) => ({
                          ...accumulator,
                          [tiersPayeur]: value,
                        }),
                        {},
                      )}
                      partResponsabilite={procedure.partResponsabilite}
                    />
                  );
                }}
              />
            </Stack>
            <Grid container>
              <Grid item xs={4}>
                <Chiffrage control={control} />
              </Grid>
              <Grid
                item
                xs={4}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <SavePrejudiceButton victime={victime} />
              </Grid>
              <Grid item xs={4} />
            </Grid>
          </Stack>
        );
      }}
    />
  );
};
