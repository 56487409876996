import { MoreHoriz } from '@mui/icons-material';
import {
  Button,
  FormControl,
  FormLabel,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useMemo, useState } from 'react';
import { Control, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ComputedPropsForm } from 'src/components/forms/ComputedPropsForm';
import { RadioFormField } from 'src/components/forms/RadioFieldForm';
import { validationSchemaNumeroPieces } from 'src/constants/prejudice/validation';
import {
  getONIAMRIIMontantDFP,
  getRIIPrixPointAIPP,
  getSelectableRIIBaremes,
} from 'src/helpers/bareme/bareme';
import { Bareme } from 'src/types/bareme.type';
import { baremeRIIRoundChoices } from 'src/types/procedure.type';
import { VictimeGender } from 'src/types/victime.type';
import * as yup from 'yup';
import {
  CalculsFormListeProjection,
  CalculsGlobal,
} from '../../../../constants/calculs';
import {
  COMMENTAIRE_PGPF_RELIQUAT,
  ListeProjectionFonctionnelPermanent,
  ListeProjectionFonctionnelPermanentRow,
  NumeroPieceValuesRow,
  PrejudiceFormListeProjection,
} from '../../../../types/prejudice.type';
import { ComputedReadFieldForm } from '../../../forms/ComputedReadFieldForm';
import { ComputedTextFieldForm } from '../../../forms/ComputedTextFieldForm';
import { TextFieldForm } from '../../../forms/TextFieldForm';
import { IMaskNumberProps, MaskNumber } from '../../../masks/MaskNumber';
import { NormalTable } from '../../../styled';
import { SelectBareme } from '../Capitalisation/SelectBareme';
import { FormNumeroPieceDialog } from '../FormNumeroPieceDialog';
interface Props {
  control: Control<PrejudiceFormListeProjection>;
  dateConsolidation: Date | undefined;
  dateNaissance: string | undefined;
  allNumerosPieces: NumeroPieceValuesRow[];
  baremes: Bareme[];
  victimeSexe: VictimeGender;
}

export const deficitFonctionnelPermanentPGPFReliquatRow = (
  IPPGPFReliquat: number,
): ListeProjectionFonctionnelPermanentRow => ({
  commentaires: COMMENTAIRE_PGPF_RELIQUAT,
  pourcentage: 0,
  valeurPoint: 0,
  montant: IPPGPFReliquat || 0,
  numerosPieces: { rows: [] },
});

export const validationSchemaDeficitFonctionnelPermanent =
  (): yup.ObjectSchema<ListeProjectionFonctionnelPermanent> =>
    yup.object({
      rows: yup
        .array()
        .defined()
        .of(
          yup.object({
            numerosPieces: validationSchemaNumeroPieces,
            commentaires: yup.string().optional().defined(),
            pourcentage: yup.number().required().min(0).max(100),
            valeurPoint: yup.number().optional(),
            montant: yup.number().required(),
          }),
        ),
    });
const initialValuesRow: ListeProjectionFonctionnelPermanentRow = {
  commentaires: '',
  montant: 0,
  pourcentage: 0,
  valeurPoint: 0,
  numerosPieces: { rows: [] },
};

export const getInitialValuesDeficitFonctionnelPermanent = (
  values: ListeProjectionFonctionnelPermanent | undefined,
): ListeProjectionFonctionnelPermanent => ({
  rows:
    values?.rows?.map((row) => ({
      ...initialValuesRow,
      ...row,
    })) || [],
});

export const DeficitFonctionnelPermanent: React.FC<Props> = ({
  control,
  dateConsolidation,
  dateNaissance,
  allNumerosPieces,
  baremes,
  victimeSexe,
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openMenuIndex, setOpenMenuIndex] = useState<number | null>(null);

  const handleMenuOpen =
    (index: number) => (event: React.MouseEvent<HTMLElement>) => {
      setOpenMenuIndex(index);
      setAnchorEl(event.currentTarget);
    };

  const handleMenuClose = () => {
    setOpenMenuIndex(null);
    setAnchorEl(null);
  };

  const { fields, append, insert, remove } = useFieldArray({
    control,
    name: 'prejudiceValues.rows',
  });

  const handleDelete = (index: number) => {
    remove(index);
    handleMenuClose();
  };

  const duplicateRow = (index: number) => {
    const rowToDuplicate = fields[index];
    insert(index, {
      ...rowToDuplicate,
    } as ListeProjectionFonctionnelPermanentRow);
    handleMenuClose();
  };

  const ageConsolidation = useMemo(
    () =>
      dateNaissance && dateConsolidation
        ? CalculsGlobal.getAgeOrZero(dateNaissance, dateConsolidation)
        : undefined,
    [dateNaissance, dateConsolidation],
  );

  const computePropsHideValeurPoint = ([baremeRII]: [string | undefined]) => {
    const bareme = baremes.find((bareme) => bareme._id === baremeRII);
    return {
      hidden: bareme?.source === 'ONIAM',
    };
  };

  return (
    <>
      <Stack direction="column" spacing={3}>
        <Stack direction="row" spacing={2} alignItems="start">
          <SelectBareme
            control={control as Control<PrejudiceFormListeProjection>}
            name="baremeRII"
            baremes={getSelectableRIIBaremes(baremes)}
            sx={{ alignSelf: 'start' }}
            label={t('bareme.type.RII.label.short')}
          />
          <ComputedPropsForm
            control={control}
            watchFields={['baremeRII']}
            compute={([baremeRII]) => {
              const bareme = baremes.find((bareme) => bareme._id === baremeRII);
              return {
                hidden: bareme?.source !== 'ONIAM',
              };
            }}
            render={() => (
              <Stack direction="column" spacing={3}>
                <Typography
                  variant="body1"
                  sx={{ marginBottom: 2, maxWidth: 850 }}
                >
                  {t('bareme.type.RIIValues.intro')}
                </Typography>
              </Stack>
            )}
          />
        </Stack>
        <ComputedPropsForm
          control={control}
          watchFields={['baremeRII']}
          compute={([baremeRII]) => {
            const bareme = baremes.find((bareme) => bareme._id === baremeRII);
            return {
              hidden: bareme?.source !== 'ONIAM',
            };
          }}
          render={() => (
            <Stack direction="column" spacing={3}>
              <Stack direction="row" spacing={2}>
                <FormControl>
                  <FormLabel>
                    {t('bareme.type.RIIValues.roundAge.label')}
                  </FormLabel>
                  <RadioFormField
                    control={control}
                    name="baremeRIIRoundAge"
                    options={baremeRIIRoundChoices.map((choice) => ({
                      label: t(
                        t(`bareme.type.RIIValues.roundAge.options.${choice}`),
                      ),
                      value: choice,
                    }))}
                    sx={{ width: 380 }}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>
                    {t('bareme.type.RIIValues.roundTauxDFP.label')}
                  </FormLabel>
                  <RadioFormField
                    control={control}
                    name="baremeRIIRoundTauxDFP"
                    options={baremeRIIRoundChoices.map((choice) => ({
                      label: t(
                        `bareme.type.RIIValues.roundTauxDFP.options.${choice}`,
                      ),
                      value: choice,
                    }))}
                    sx={{ width: 380 }}
                  />
                </FormControl>
              </Stack>
            </Stack>
          )}
        />
      </Stack>
      <TableContainer component={Paper}>
        <NormalTable size="medium">
          <TableHead>
            <TableRow>
              <TableCell>{t('numeroPiece.form.columnHeader')}</TableCell>
              <TableCell>
                {t(
                  'prejudice.prejudicesFormTypes.LISTE_PROJECTION.DEFICIT_FONCTIONNEL_PERMANENT.fields.commentaires.columnHeader',
                )}
              </TableCell>
              {ageConsolidation && (
                <TableCell align="center">
                  {t(
                    'prejudice.prejudicesFormTypes.LISTE_PROJECTION.DEFICIT_FONCTIONNEL_PERMANENT.fields.ageConsolidation.columnHeader',
                  )}
                </TableCell>
              )}
              <TableCell align="center">
                {t(
                  'prejudice.prejudicesFormTypes.LISTE_PROJECTION.DEFICIT_FONCTIONNEL_PERMANENT.fields.pourcentage.columnHeader',
                )}
              </TableCell>
              <ComputedPropsForm
                control={control}
                watchFields={['baremeRII']}
                compute={computePropsHideValeurPoint}
                render={() => (
                  <TableCell align="center">
                    {t(
                      'prejudice.prejudicesFormTypes.LISTE_PROJECTION.DEFICIT_FONCTIONNEL_PERMANENT.fields.valeurPoint.columnHeader',
                    )}
                  </TableCell>
                )}
              />
              <TableCell align="center">
                {t(
                  'prejudice.prejudicesFormTypes.LISTE_PROJECTION.DEFICIT_FONCTIONNEL_PERMANENT.fields.montant.columnHeader',
                )}
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((field, index) => (
              <TableRow key={field.id}>
                <TableCell>
                  <FormNumeroPieceDialog
                    control={control}
                    name={`prejudiceValues.rows.${index}.numerosPieces`}
                    allOtherNumeroPieces={allNumerosPieces}
                  />
                </TableCell>
                <TableCell>
                  <TextFieldForm
                    control={control}
                    name={`prejudiceValues.rows.${index}.commentaires`}
                    sx={{ width: 350 }}
                  />
                </TableCell>
                {ageConsolidation && (
                  <TableCell align="center">
                    {t('forms.fields.age.value', {
                      count: ageConsolidation.age,
                      context: ageConsolidation.isExactlyZero
                        ? 'exactly_zero'
                        : undefined,
                    })}
                  </TableCell>
                )}
                <TableCell>
                  <TextFieldForm
                    control={control}
                    name={`prejudiceValues.rows.${index}.pourcentage`}
                    InputProps={{
                      inputComponent: MaskNumber as any,
                      inputProps: {
                        numberMask: { scale: 0, min: 0, max: 100 },
                        suffix: '%',
                      } as IMaskNumberProps,
                    }}
                  />
                </TableCell>
                <ComputedPropsForm
                  control={control}
                  watchFields={['baremeRII']}
                  compute={computePropsHideValeurPoint}
                  render={() => (
                    <TableCell>
                      <ComputedTextFieldForm
                        control={control}
                        name={`prejudiceValues.rows.${index}.valeurPoint`}
                        watchFields={[
                          `prejudiceValues.rows.${index}.pourcentage`,
                          'baremeRII',
                          'baremeRIIRoundAge',
                          'baremeRIIRoundTauxDFP',
                        ]}
                        compute={([pourcentage, baremeRII]) => {
                          const bareme = baremes.find(
                            (bareme) =>
                              bareme._id === baremeRII &&
                              bareme.type === 'RII' &&
                              bareme.source !== 'ONIAM',
                          );
                          if (!bareme || !dateNaissance || !dateConsolidation) {
                            return undefined;
                          }
                          const valeurPoint = getRIIPrixPointAIPP({
                            bareme,
                            tauxDFP: pourcentage,
                            victimeSexe,
                            victimeDateNaissance: new Date(dateNaissance),
                            dateConsolidation: new Date(dateConsolidation),
                          });
                          return valeurPoint || undefined;
                        }}
                        InputProps={{
                          inputComponent: MaskNumber as any,
                          inputProps: {
                            numberMask: { scale: 2, signed: true },
                            suffix: '€',
                          } as IMaskNumberProps,
                        }}
                      />
                    </TableCell>
                  )}
                />
                <TableCell>
                  <ComputedTextFieldForm
                    control={control}
                    name={`prejudiceValues.rows.${index}.montant`}
                    watchFields={[
                      `prejudiceValues.rows.${index}.pourcentage`,
                      `prejudiceValues.rows.${index}.valeurPoint`,
                      'baremeRII',
                      'baremeRIIRoundAge',
                      'baremeRIIRoundTauxDFP',
                    ]}
                    compute={([
                      pourcentage,
                      valeurPoint,
                      baremeRII,
                      baremeRIIRoundAge,
                      baremeRIIRoundTauxDFP,
                    ]) => {
                      const bareme = baremes.find(
                        (bareme) =>
                          bareme._id === baremeRII && bareme.type === 'RII',
                      );
                      if (bareme?.source !== 'ONIAM') {
                        return CalculsFormListeProjection.getMontantDeficitFonctionnelPermanent(
                          {
                            pourcentage: pourcentage || 0,
                            valeurPoint: valeurPoint || 0,
                          },
                        );
                      }
                      if (!dateNaissance || !dateConsolidation) {
                        return undefined;
                      }
                      const montantDFP = getONIAMRIIMontantDFP({
                        bareme,
                        tauxDFP: pourcentage || 0,
                        victimeSexe,
                        victimeDateNaissance: new Date(dateNaissance),
                        dateConsolidation: new Date(dateConsolidation),
                        baremeRIIRoundAge: baremeRIIRoundAge || null,
                        baremeRIIRoundTauxDFP: baremeRIIRoundTauxDFP || null,
                      });
                      return montantDFP ?? 0;
                    }}
                    InputProps={{
                      inputComponent: MaskNumber as any,
                      inputProps: {
                        numberMask: { scale: 2, signed: true },
                        suffix: '€',
                      } as IMaskNumberProps,
                    }}
                    editedFieldsName="editedFields"
                  />
                </TableCell>
                <TableCell align="center">
                  <IconButton onClick={handleMenuOpen(index)}>
                    <MoreHoriz />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={openMenuIndex === index}
                    onClose={handleMenuClose}
                  >
                    <MenuItem onClick={() => handleDelete(index)}>
                      {t('forms.table.deleteRow', { index: index + 1 })}
                    </MenuItem>
                    <MenuItem onClick={() => duplicateRow(index)}>
                      {t('forms.table.duplicateRow', { index: index + 1 })}
                    </MenuItem>
                  </Menu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </NormalTable>
      </TableContainer>
      <Button
        sx={{ alignSelf: 'center' }}
        onClick={() => {
          append(initialValuesRow);
        }}
      >
        {t('forms.table.addRow')}
      </Button>
      <ComputedPropsForm
        control={control}
        watchFields={['prejudiceValues.rows']}
        compute={([rows]) => ({
          props: {
            montantTotal:
              CalculsFormListeProjection.getDeficitFonctionnelPermanentIndemnite(
                {
                  rows: rows as ListeProjectionFonctionnelPermanentRow[],
                },
              ),
          },
        })}
        render={(values) => {
          const { montantTotal } = values as {
            montantTotal: ReturnType<
              typeof CalculsFormListeProjection.getDeficitFonctionnelPermanentIndemnite
            >;
          };

          return (
            <>
              <ComputedReadFieldForm
                name="montantTotal"
                control={control}
                watchFields={[]}
                customValues={[montantTotal]}
                compute={(_, customValues) => customValues?.[0] || 0}
              />
            </>
          );
        }}
      />
    </>
  );
};
