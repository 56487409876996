import { TableCell, TableHead, TableRow } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TooltipTableHeader } from '../../TooltipTableHeader';

interface Props {
  tiersPayeurs: string[];
  isCalculCapitalisation: boolean | undefined;
  hideDepenseRecurrente: boolean | undefined;
  hidePeriode: boolean | undefined;
  isVictimeIndirecte: boolean | undefined;
  shouldNotDisplayCapitalisation: boolean;
}

export const TableDepensesHeader: React.FC<Props> = ({
  tiersPayeurs,
  isCalculCapitalisation,
  hideDepenseRecurrente,
  isVictimeIndirecte,
  hidePeriode,
  shouldNotDisplayCapitalisation,
}) => {
  const { t } = useTranslation();
  return (
    <TableHead>
      {isCalculCapitalisation && !shouldNotDisplayCapitalisation ? (
        <TableRow>
          <TableCell />
          <TableCell
            sx={{
              left: 0,
              zIndex: 3,
              backgroundColor: 'white',
            }}
          />
          <TableCell colSpan={12 + (tiersPayeurs.length || 0) * 2} />
          <TableCell colSpan={5} align="center">
            {t(
              'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE_CAPITALISATION.fields.rows.capitalisation.title',
            )}
          </TableCell>
          {tiersPayeurs.length > 0 && (
            <TableCell colSpan={tiersPayeurs.length} align="center">
              {t(
                'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE_CAPITALISATION.fields.rows.capitalisationTiersPayeurs.title',
              )}
            </TableCell>
          )}
        </TableRow>
      ) : null}
      <TableRow>
        <TableCell sx={{ zIndex: 3 }}>
          {t('numeroPiece.form.columnHeader')}
        </TableCell>
        {isVictimeIndirecte && (
          <TableCell
            sx={{
              minWidth: 250,
              position: 'sticky',
              left: 0,
              zIndex: 3,
            }}
          >
            {t(
              'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE.fields.rows.victimeIndirecte.columnHeader',
            )}
          </TableCell>
        )}
        <TableCell
          variant={isVictimeIndirecte ? undefined : 'head'}
          sx={
            isVictimeIndirecte
              ? {}
              : {
                  minWidth: 300,
                  position: 'sticky',
                  left: 0,
                  zIndex: 3,
                }
          }
        >
          {t(
            'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE.fields.rows.intitule.columnHeader',
          )}
        </TableCell>
        {!hidePeriode && (
          <TableCell align="center">
            {t(
              'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE.fields.rows.dateDebut.periodeColumnHeader',
            )}
          </TableCell>
        )}
        {!hideDepenseRecurrente && (
          <>
            <TableCell align="center" colSpan={2}>
              {t(
                'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE.fields.rows.frequenceMontant.periodiciteColumnHeader',
              )}
            </TableCell>
            <TableCell align="center">
              {t(
                'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE.fields.rows.quantite.columnHeader',
                {
                  context: isCalculCapitalisation
                    ? 'capitalisation'
                    : undefined,
                },
              )}
            </TableCell>
          </>
        )}
        <TableCell align="center">
          {t(
            'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE.fields.rows.dateJustificatif.columnHeader',
          )}
        </TableCell>
        <TableCell align="center">
          <TooltipTableHeader
            tooltipTitle={
              t(
                'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE.fields.rows.montantsDejaRevalorises.headerTooltip',
              ) || ''
            }
            headerLabel={
              t(
                'prejudice.revalorisation.columnHeaders.montantsDejaRevalorises',
              ) || ''
            }
          />
        </TableCell>
        <TableCell align="center">
          {t(
            'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE.fields.rows.montantUnitaire.columnHeader',
          )}
        </TableCell>
        {isCalculCapitalisation && !shouldNotDisplayCapitalisation ? (
          <>
            <TableCell align="center">
              {t(
                'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE_CAPITALISATION.fields.rows.montantUnitaireAnnualise.columnHeader',
              )}
            </TableCell>
            <TableCell align="center" sx={{ minWidth: '150px' }}>
              {t(
                'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE_CAPITALISATION.fields.rows.montantUnitaireAnnualise.revalorise.columnHeader',
              )}
            </TableCell>
          </>
        ) : null}
        {tiersPayeurs.map((tiersPayeur) => (
          <TableCell key={tiersPayeur} align="center">
            {t(
              'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE.fields.rows.priseEnChargeTiersPayeurs.columnHeader',
              {
                tiersPayeur,
              },
            )}
          </TableCell>
        ))}
        <TableCell align="center">
          {t(
            'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE.fields.rows.montantTotal.columnHeader',
          )}
        </TableCell>
        {tiersPayeurs.map((tiersPayeur) => (
          <TableCell key={tiersPayeur} align="center">
            {t(
              'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE.fields.rows.priseEnChargeTiersPayeursProratises.columnHeader',
              {
                tiersPayeur,
              },
            )}
          </TableCell>
        ))}
        <TableCell align="center">
          {t(
            'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE.fields.rows.resteACharge.columnHeader',
          )}
        </TableCell>
        <TableCell align="center" sx={{ minWidth: '150px' }}>
          {t(
            'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE.fields.rows.resteACharge.revalorise.columnHeader',
          )}
        </TableCell>
        {isCalculCapitalisation && !shouldNotDisplayCapitalisation ? (
          <>
            <TableCell align="center">
              <TooltipTableHeader
                tooltipTitle={t(
                  'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE_CAPITALISATION.fields.rows.capitalisation.dateAttribution.age.headerTooltip',
                )}
                headerLabel={t(
                  'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE_CAPITALISATION.fields.rows.capitalisation.dateAttribution.age.columnHeader',
                )}
              />
            </TableCell>
            <TableCell align="center" colSpan={2}>
              {t(
                'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE_CAPITALISATION.fields.rows.capitalisation.ageDernierArrerage.derniersArreragesColumnHeader',
              )}
            </TableCell>
            <TableCell align="center">
              {t(
                'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE_CAPITALISATION.fields.rows.capitalisation.coefficient.columnHeader',
              )}
            </TableCell>
            <TableCell align="center">
              {t(
                'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE_CAPITALISATION.fields.rows.capitalisation.montantCapitalise.columnHeader',
              )}
            </TableCell>
            {tiersPayeurs.length > 0 && (
              <>
                {tiersPayeurs.map((tiersPayeur) => (
                  <TableCell key={tiersPayeur} align="center">
                    {t(
                      'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE_CAPITALISATION.fields.rows.capitalisationTiersPayeurs.parTiersPayeur.columnHeader',
                      {
                        tiersPayeur,
                      },
                    )}
                  </TableCell>
                ))}
              </>
            )}
          </>
        ) : null}
        <TableCell />
      </TableRow>
    </TableHead>
  );
};
