import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  FormControl,
  FormLabel,
  Grid,
  InputAdornment,
  Stack,
  TableCell,
} from '@mui/material';
import i18next from 'i18next';
import React, { useMemo } from 'react';
import { Control, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DintilhacText } from 'src/components/client/prejudiceFormComponents/DintilhacText';
import { ComputedPropsForm } from 'src/components/forms/ComputedPropsForm';
import { RadioFormField } from 'src/components/forms/RadioFieldForm';
import { ResourcesButton } from 'src/components/resources/ResourcesButton';
import { CalculsGlobal } from 'src/constants/calculs';
import { prejudiceBaseDefaultValues } from 'src/constants/prejudice/defaultValues';
import { validationSchemaPrejudiceBase } from 'src/constants/prejudice/validation';
import { prejudicesData } from 'src/constants/prejudices';
import {
  getDefaultListeProjectionBareme,
  getIndemnisationBaremeSourceLabel,
  getSelectableIndemnisationBaremes,
} from 'src/helpers/bareme/bareme';
import {
  getCapitalisationBaremes,
  getCapitalisationCoefficient,
  getProrataTemporisBaremes,
} from 'src/helpers/prejudices/capitalisation';
import { isListeProjectionTotalDisplayed } from 'src/helpers/prejudices/prejudice';
import { Bareme, BaremeCapitalisationValue } from 'src/types/bareme.type';
import {
  baremeIndemnisationValueChoices,
  baremeRIIRoundChoices,
  Procedure,
} from 'src/types/procedure.type';
import { Victime } from 'src/types/victime.type';
import * as yup from 'yup';
import {
  ListeProjectionAgrement,
  ListeProjectionFonctionnelPermanent,
  ListeProjectionIncidenceProfessionelle,
  ListeProjectionMaladie,
  PGPFReliquatAndPGPFReliquatActivationStatus,
  PrejudiceFormListeProjection,
  PrejudiceType,
} from '../../../types/prejudice.type';
import { TextFieldForm } from '../../forms/TextFieldForm';
import { PrejudiceContainer } from '../PrejudiceContainer';
import { SelectBareme } from '../prejudiceFormComponents/Capitalisation/SelectBareme';
import { Chiffrage } from '../prejudiceFormComponents/Chiffrage';
import { DintilhacButton } from '../prejudiceFormComponents/DintilhacButton';
import {
  DeficitFonctionnelPermanent,
  getInitialValuesDeficitFonctionnelPermanent,
  validationSchemaDeficitFonctionnelPermanent,
} from '../prejudiceFormComponents/listeProjection/DeficitFonctionnelPermanent';
import {
  getInitialValuesIncidenceProfessionnelle,
  IncidenceProfessionnelle,
  validationSchemaIncidenceProfessionnelle,
} from '../prejudiceFormComponents/listeProjection/IncidenceProfessionnelle';
import {
  getInitialValuesPrejudiceAgrement,
  PrejudiceAgrement,
  validationSchemaPrejudiceAgrement,
} from '../prejudiceFormComponents/listeProjection/PrejudiceAgrement';
import {
  getInitialValuesPrejudiceMaladie,
  PrejudiceMaladie,
  validationSchemaPrejudiceMaladie,
} from '../prejudiceFormComponents/listeProjection/PrejudiceMaladie';
import { ProrataTemporisDeces } from '../prejudiceFormComponents/ProrataTemporisDeces';
import { TotalDeficit } from '../prejudiceFormComponents/TotalDeficit';
import { PrejudiceFormProps } from '../PrejudiceFormProps';
import { SavePrejudiceButton } from '../SavePrejudiceButton';

const getValidationSchemaPrejudiceValues = ({
  prejudiceType,
  procedure,
  victime,
}: {
  prejudiceType: PrejudiceType;
  procedure: Procedure;
  victime: Victime;
}) =>
  prejudiceType === 'PREJUDICE_AGREMENT' ||
  prejudiceType === 'AUTRES_POSTES_DE_PREJUDICES'
    ? validationSchemaPrejudiceAgrement()
    : prejudiceType === 'DEFICIT_FONCTIONNEL_PERMANENT'
      ? validationSchemaDeficitFonctionnelPermanent()
      : prejudiceType === 'PREJUDICE_ESTHETIQUE_PERMANENT' ||
          prejudiceType === 'PREJUDICE_ESTHETIQUE_TEMPORAIRE' ||
          prejudiceType === 'SOUFFRANCES_ENDUREES' ||
          prejudiceType === 'PREJUDICE_SEXUEL'
        ? validationSchemaPrejudiceMaladie({
            procedure,
            victime,
            prejudiceType,
          })
        : prejudiceType === 'INCIDENCE_PROFESSIONNELLE'
          ? validationSchemaIncidenceProfessionnelle(
              victime.dateNaissance
                ? new Date(victime.dateNaissance).getFullYear()
                : 1800,
              procedure.dateLiquidation
                ? new Date(procedure.dateLiquidation)
                : undefined,
            )
          : undefined;

export const validationSchemaListeProjection = ({
  prejudiceType,
  procedure,
  victime,
}: {
  prejudiceType: PrejudiceType;
  procedure: Procedure;
  victime: Victime;
}): yup.ObjectSchema<PrejudiceFormListeProjection> => {
  const validationSchemaPrejudiceValues = getValidationSchemaPrejudiceValues({
    prejudiceType,
    procedure,
    victime,
  });
  const validationSchema: yup.ObjectSchema<PrejudiceFormListeProjection> =
    validationSchemaPrejudiceBase.shape({
      //TODO improve types
      prejudiceValues: validationSchemaPrejudiceValues as any,
      montantTotal: yup.number().required(),
      enableProrataTemporisDeces: yup.boolean().required(),
      baremeCapitalisation: yup.string().when('enableProrataTemporisDeces', {
        is: (enableProrataTemporisDeces: boolean) =>
          enableProrataTemporisDeces &&
          !prejudicesData[prejudiceType || 'DEPENSE_SANTE_FUTURES']
            .shouldNotDisplayProrataTemporis,
        then: (schema) => schema.required(),
      }),
      baremeIndemnisation: yup.string().optional(),
      baremeValueChoice: yup.string().optional(),
      baremeRII: yup.string().optional(),
      baremeRIIRoundAge: yup
        .string()
        .oneOf(baremeRIIRoundChoices)
        .nullable()
        .optional()
        .transform((value) => (value === '' ? null : value)),
      baremeRIIRoundTauxDFP: yup
        .string()
        .nullable()
        .oneOf(baremeRIIRoundChoices)
        .optional()
        .transform((value) => (value === '' ? null : value)),
      coefficientCapitalisationAgeConsolidation: yup
        .number()
        .when('enableProrataTemporisDeces', {
          is: (enableProrataTemporisDeces: boolean) =>
            enableProrataTemporisDeces &&
            !prejudicesData[prejudiceType || 'DEPENSE_SANTE_FUTURES']
              .shouldNotDisplayProrataTemporis,
          then: (schema) => schema.required().min(0),
        }),
      montantProratise: yup.number().when('enableProrataTemporisDeces', {
        is: (enableProrataTemporisDeces: boolean) =>
          enableProrataTemporisDeces &&
          !prejudicesData[prejudiceType || 'DEPENSE_SANTE_FUTURES']
            .shouldNotDisplayProrataTemporis,
        then: (schema) => schema.required(),
      }),
      anneeCapitalisation: yup.number().optional(),
    });
  return validationSchema;
};

export const listeProjectionDefaultValues = ({
  prejudiceType,
  victime,
  values,
  dateConsolidation,
  dateLiquidation,
  baremes,
  procedure,
}: {
  prejudiceType: PrejudiceType;
  victime: Victime;
  values: PrejudiceFormListeProjection | undefined;
  dateConsolidation: Date | undefined;
  dateLiquidation: Date | undefined;
  baremes: Bareme[];
  procedure: Procedure;
}): PrejudiceFormListeProjection => {
  const prorataTemporisCapitalisationBaremes = getProrataTemporisBaremes(
    baremes,
    victime.sexe,
  );
  const baremeCapitalisationId =
    values?.baremeCapitalisation ||
    (values?.enableProrataTemporisDeces
      ? getDefaultListeProjectionBareme(prorataTemporisCapitalisationBaremes)
          ?._id
      : undefined);
  const baremeCapitalisation = baremeCapitalisationId
    ? baremes.find((bareme) => bareme._id === baremeCapitalisationId)
    : undefined;

  return {
    ...prejudiceBaseDefaultValues({
      prejudiceType,
      values,
    }),
    notes: values?.notes || '',
    prejudiceValues:
      prejudiceType === 'PREJUDICE_AGREMENT' ||
      prejudiceType === 'AUTRES_POSTES_DE_PREJUDICES' ||
      prejudiceType === 'DEFICIT_FONCTIONNEL_PERMANENT' ||
      prejudiceType === 'PREJUDICE_ESTHETIQUE_PERMANENT' ||
      prejudiceType === 'PREJUDICE_ESTHETIQUE_TEMPORAIRE' ||
      prejudiceType === 'SOUFFRANCES_ENDUREES' ||
      prejudiceType === 'PREJUDICE_SEXUEL' ||
      prejudiceType === 'INCIDENCE_PROFESSIONNELLE'
        ? {
            ...(prejudiceType === 'PREJUDICE_AGREMENT' ||
            prejudiceType === 'AUTRES_POSTES_DE_PREJUDICES'
              ? getInitialValuesPrejudiceAgrement(
                  values?.prejudiceValues as
                    | ListeProjectionAgrement
                    | undefined,
                )
              : prejudiceType === 'DEFICIT_FONCTIONNEL_PERMANENT'
                ? getInitialValuesDeficitFonctionnelPermanent(
                    values?.prejudiceValues as
                      | ListeProjectionFonctionnelPermanent
                      | undefined,
                  )
                : prejudiceType === 'PREJUDICE_ESTHETIQUE_PERMANENT' ||
                    prejudiceType === 'PREJUDICE_ESTHETIQUE_TEMPORAIRE' ||
                    prejudiceType === 'SOUFFRANCES_ENDUREES' ||
                    prejudiceType === 'PREJUDICE_SEXUEL'
                  ? getInitialValuesPrejudiceMaladie(
                      values?.prejudiceValues as
                        | ListeProjectionMaladie
                        | undefined,
                    )
                  : getInitialValuesIncidenceProfessionnelle(
                      values?.prejudiceValues as
                        | ListeProjectionIncidenceProfessionelle
                        | undefined,
                    )),
          }
        : undefined,
    montantTotal: values?.montantTotal || 0,
    enableProrataTemporisDeces: values?.enableProrataTemporisDeces || false,
    coefficientCapitalisationAgeConsolidation:
      values?.coefficientCapitalisationAgeConsolidation ||
      (values?.enableProrataTemporisDeces
        ? !!victime.dateNaissance &&
          dateConsolidation &&
          dateLiquidation &&
          baremeCapitalisation
          ? getCapitalisationCoefficient({
              sexe: victime.sexe,
              baremeValues:
                baremeCapitalisation.values as BaremeCapitalisationValue[],
              ageDateAttribution: CalculsGlobal.getAge(
                victime.dateNaissance ?? '',
                dateConsolidation,
              ),
              ageDernierArrerage: null,
              dateNaissance: victime.dateNaissance,
              dateLiquidation,
              isDernierArrerageViager: true,
            }) || null
          : null
        : undefined),
    anneeCapitalisation: values?.anneeCapitalisation || undefined,
    baremeCapitalisation: baremeCapitalisationId,
    baremeValueChoice:
      (values
        ? values?.baremeValueChoice
        : procedure.baremes?.baremeIndemnisationValueChoice) ?? 'maximum',
    baremeIndemnisation:
      (values
        ? values?.baremeIndemnisation
        : procedure.baremes?.baremeIndemnisationId) ?? undefined,
    baremeRII:
      (values ? values?.baremeRII : procedure.baremes?.baremeRIIId) ??
      undefined,
    baremeRIIRoundAge:
      (values
        ? values?.baremeRIIRoundAge
        : procedure.baremes?.baremeRIIRoundAge) ?? undefined,
    baremeRIIRoundTauxDFP:
      (values
        ? values?.baremeRIIRoundTauxDFP
        : procedure.baremes?.baremeRIIRoundTauxDFP) ?? undefined,
    montantProratise:
      (values
        ? values?.montantProratise
        : procedure.baremes?.forfaitJourDFTP) ?? 0,
  };
};

interface Props extends PrejudiceFormProps<PrejudiceFormListeProjection> {
  PGPFReliquat: PGPFReliquatAndPGPFReliquatActivationStatus;
}

export const FormListeProjection: React.FC<Props> = ({
  prejudiceType,
  victime,
  procedure,
  dateConsolidation,
  values,
  partResponsabilite,
  displayTotalPartResponsabilite,
  baremes,
  PGPFReliquat,
  dateLiquidation,
  allNumerosPieces,
  ...props
}) => {
  const { t } = useTranslation();
  const prorataTemporisCapitalisationBaremes = useMemo(
    () => getProrataTemporisBaremes(baremes, victime.sexe),
    [baremes, victime.sexe],
  );
  const capitalisationBaremes = useMemo(
    () => getCapitalisationBaremes(baremes, victime.sexe),
    [baremes, victime.sexe],
  );
  const baremesIndemnisation = getSelectableIndemnisationBaremes(baremes);
  const defaultValues = useMemo(
    () =>
      listeProjectionDefaultValues({
        prejudiceType,
        victime,
        dateConsolidation,
        values,
        baremes,
        dateLiquidation,
        procedure,
      }),
    [prejudiceType, victime, procedure, dateConsolidation, values, baremes],
  );

  const useFormReturn = useForm<PrejudiceFormListeProjection>({
    defaultValues,
    resolver: yupResolver(
      validationSchemaListeProjection({
        prejudiceType,
        victime,
        procedure,
      }),
    ),
  });

  const isPrejudiceEsthetiqueOrSouffranceEnduree =
    prejudiceType === 'PREJUDICE_ESTHETIQUE_PERMANENT' ||
    prejudiceType === 'PREJUDICE_ESTHETIQUE_TEMPORAIRE' ||
    prejudiceType === 'SOUFFRANCES_ENDUREES';

  const displayBareme = Boolean(
    prejudiceType === 'DEFICIT_FONCTIONNEL_PERMANENT' ||
      isPrejudiceEsthetiqueOrSouffranceEnduree,
  );

  const dateNaissance = victime.dateNaissance
    ? new Date(victime.dateNaissance)
    : null;

  const dateDeces = victime.dateDeces ? new Date(victime.dateDeces) : null;
  const { control, setValue } = useMemo(() => useFormReturn, [useFormReturn]);

  const baremeValueChoice = useWatch({ control, name: 'baremeValueChoice' });
  const baremeIndemnisationValue = useWatch({
    control,
    name: 'baremeIndemnisation',
  });
  const baremeIndemnisation = baremesIndemnisation.find(
    (bareme) => bareme._id === baremeIndemnisationValue,
  );

  const [showDintilhac, setShowDintilhac] = React.useState<boolean>(false);
  return (
    <PrejudiceContainer<PrejudiceFormListeProjection>
      {...props}
      prejudiceType={prejudiceType}
      victime={victime}
      baremes={baremes}
      procedure={procedure}
      {...useFormReturn}
      renderPrejudice={() => (
        <Stack direction="column" spacing={4} flex={1}>
          <TextFieldForm
            control={control}
            name="notes"
            label={t('prejudice.fields.notes.label')}
            placeholder={t('prejudice.fields.notes.placeholder') || ''}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {prejudiceType !== 'AUTRES_POSTES_DE_PREJUDICES' && (
                    <DintilhacButton onClick={() => setShowDintilhac(true)} />
                  )}
                </InputAdornment>
              ),
            }}
            multiline
            maxRows={10}
            fullWidth
          />
          <DintilhacText
            open={showDintilhac}
            setOpen={setShowDintilhac}
            label={t('prejudice.fields.dintilhac.label')}
            content={i18next.t(
              `prejudice.prejudicesTypes.${prejudiceType}.introduction`,
              {
                defaultValue: '',
              },
            )}
          />
          {isPrejudiceEsthetiqueOrSouffranceEnduree ? (
            <TableCell align="left">
              <Box display="flex" alignItems="center" gap={2}>
                <SelectBareme
                  control={control as Control<PrejudiceFormListeProjection>}
                  baremes={baremesIndemnisation ?? []}
                  name="baremeIndemnisation"
                  afterOnChange={(value) => {
                    const baremeIndemnisation = baremesIndemnisation.find(
                      (bareme) => bareme._id === value,
                    );
                    if (baremeIndemnisation?.source === 'FGTI') {
                      setValue('baremeValueChoice', 'minimum');
                    }
                  }}
                  getBaremeSourceLabelFunction={(bareme) =>
                    getIndemnisationBaremeSourceLabel(bareme, true)
                  }
                />
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    {t('bareme.type.IndemnisationValues.baremeValueChoice')}
                  </FormLabel>
                  <RadioFormField
                    control={control}
                    name="baremeValueChoice"
                    row
                    options={baremeIndemnisationValueChoices.map((value) => ({
                      label: t(
                        `bareme.type.IndemnisationValues.${value}.label`,
                      ),
                      value,
                      isDisabled: (value: string) => {
                        if (!baremeIndemnisation) {
                          return true;
                        }
                        switch (value) {
                          case 'minimum':
                            return false;
                          case 'average':
                          case 'maximum':
                            return baremeIndemnisation?.source === 'FGTI';
                          default:
                            return true;
                        }
                      },
                    }))}
                  />
                </FormControl>
              </Box>
            </TableCell>
          ) : null}
          {isPrejudiceEsthetiqueOrSouffranceEnduree ||
          prejudiceType === 'PREJUDICE_SEXUEL' ? (
            <PrejudiceMaladie
              control={control}
              procedure={procedure}
              victime={victime}
              prejudiceType={prejudiceType}
              allNumerosPieces={allNumerosPieces}
              baremeValueChoice={baremeValueChoice}
              baremeIndemnisation={baremeIndemnisation}
            />
          ) : prejudiceType === 'PREJUDICE_AGREMENT' ||
            prejudiceType === 'AUTRES_POSTES_DE_PREJUDICES' ? (
            <PrejudiceAgrement
              control={control}
              allNumerosPieces={allNumerosPieces}
            />
          ) : prejudiceType === 'DEFICIT_FONCTIONNEL_PERMANENT' ? (
            <DeficitFonctionnelPermanent
              control={control}
              dateConsolidation={dateConsolidation}
              dateNaissance={victime.dateNaissance || undefined}
              allNumerosPieces={allNumerosPieces}
              baremes={baremes}
              victimeSexe={victime.sexe}
            />
          ) : prejudiceType === 'INCIDENCE_PROFESSIONNELLE' ? (
            <IncidenceProfessionnelle
              control={control}
              PGPFReliquat={PGPFReliquat}
              partResponsabilite={partResponsabilite}
              procedure={procedure}
              dateLiquidation={dateLiquidation}
              baremes={capitalisationBaremes}
              dateNaissance={dateNaissance}
              dateDeces={dateDeces}
              sexe={victime.sexe}
              allNumerosPieces={allNumerosPieces}
            />
          ) : null}
          {displayTotalPartResponsabilite &&
          partResponsabilite &&
          isListeProjectionTotalDisplayed({
            prejudiceType,
          }) ? (
            <Stack maxWidth="400px" alignItems="end" alignSelf="end">
              <ComputedPropsForm
                control={control}
                watchFields={['montantTotal']}
                compute={([montantTotal]) => {
                  return {
                    props: {
                      total: montantTotal,
                      totalPartResponsabilite:
                        Number(montantTotal) * partResponsabilite,
                    },
                  };
                }}
                render={({ total, totalPartResponsabilite }) => (
                  <TotalDeficit
                    totalMontantTotal={total}
                    totalPartResponsabilite={totalPartResponsabilite}
                    displayPartResponsabilite={displayTotalPartResponsabilite}
                    partResponsabilite={partResponsabilite}
                  />
                )}
              />
            </Stack>
          ) : null}
          {victime.dateNaissance &&
            victime.dateDeces &&
            dateLiquidation &&
            dateConsolidation &&
            !prejudicesData[prejudiceType || 'DEPENSE_SANTE_FUTURES']
              .shouldNotDisplayProrataTemporis && (
              <ProrataTemporisDeces
                control={control}
                dateDeces={new Date(victime.dateDeces)}
                dateConsolidation={dateConsolidation || new Date()}
                dateLiquidation={dateLiquidation}
                victime={victime}
                baremes={prorataTemporisCapitalisationBaremes}
                partResponsabilite={partResponsabilite}
              />
            )}
          <Grid container>
            <Grid item xs={4}>
              <Chiffrage control={control} />
            </Grid>
            <Grid
              item
              xs={4}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <SavePrejudiceButton victime={victime} />
            </Grid>
            <Grid
              item
              xs={4}
              display="flex"
              alignItems="center"
              justifyContent="end"
            >
              {displayBareme && (
                <ResourcesButton
                  baremeType={
                    isPrejudiceEsthetiqueOrSouffranceEnduree
                      ? 'Indemnisation'
                      : 'RII'
                  }
                  isMultipleBaremes
                />
              )}
            </Grid>
          </Grid>
        </Stack>
      )}
    />
  );
};
